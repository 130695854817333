<template>
	<div class="ghost-identity">
		<router-link class="ghost-link" :to="{name: 'ghost-logout'}">
			<fa class="icon" icon="chevron-left"/>
			{{ $t("impersonating") }}
			<template v-if="customer"><b>{{customer.domain}}</b></template>
			<loader :inline="true" v-else></loader>
		</router-link>

		<div class="demo">
			<label class="switch switch-xs" style="margin-bottom: 0 !important;">
				<input type="checkbox" v-model="isDemo"
					   :checked="false">
				<div class="slider round" :style="organizationColor" />
			</label>

			<span>Demo mode</span>
		</div>

		<button class="print-button pull-right" @click.prevent="print">
			<fa icon="print"/>
		</button>

		<router-link :to="{name: 'frontend.faq'}" class="faq-page pull-right">
			FAQ
		</router-link>
	</div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables/all";

.ghost-identity {
	display: flex;
	align-items: center;
	gap: 10px;

	padding-right: 10px;

	width: 100%;
	color: #fff;

	background: $digital-blue;
	//background-image: linear-gradient(to right, $power-pink, 90%, $power-pink-dark);

	.ghost-link{
		color: #fff;
		display: inline-block;
		padding: 15px 30px;
		font-style: italic;
		&:hover {
			opacity: .7;
		}
	}

	.icon {
		font-size: 10pt;
		margin-right: 5px;
	}

	.demo {
		margin-left: auto;

		display: flex;
		align-items: center;
		gap: 6px;
	}

	.faq-page {
		padding: 3px 20px;
		background: #ffffff;
		border-radius: 2px;
		color: $digital-blue;
		font-weight: 700;
		transition: background linear 100ms;

		&:hover {
			transition: background linear 200ms;
			background: rgba(#ffffff, .8);
		}
	}

	.print-button {
		padding: 3px 20px;
		border:0;
		outline: 0;
		background: #ffffff;
		border-radius: 2px;
		color: $digital-blue;
		font-weight: 700;
		transition: background linear 100ms;

		&:hover {
			transition: background linear 200ms;
			background: rgba(#ffffff, .8);
		}
	}

	.admin-panel__button {
		float: right;
		margin-left: 20px;
		border-radius: 3px;
		font-size: 12px;
		cursor: pointer;
		transition: all 0.2s linear;

		font-weight: 500;
		padding: 3px 8px 2px 6px;
		background: white;
		color: #4096ee;

		&:hover {
			color: #2d79c8;
		}
	}
}

@media screen and (max-width: 560px) {
	.admin-panel__button {
		display: none;
	}

	.ghost-identity {
		.faq-page {
			display: none;
		}
	}
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;

	label {
		margin-bottom: 0 !important;
	}

	&.switch-sm {
		width: 50px;
		height: 25px;

		.slider:before {
			width: 17px;
			height: 17px;
		}
	}

	&.switch-xs {
		width: 46px;
		height: 20px;

		.slider:before {
			width: 12px;
			height: 12px;
		}
	}
}

/* Hide default HTML checkbox */
.switch input {
	display: none;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: #2196F3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
</style>

<script>
import { mapGetters } from 'vuex'

export default {
	computed:{
		...mapGetters('identity', {
			identity: 'getIdentity'
		}),
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),
		...mapGetters('ghost', {
			ghostIdentity: 'getGhostIdentity',
			demo: 'isDemo'
		}),

		organizationColor () {
			return this.isDemo ? {
				backgroundColor: $org('colors.standard.button.outlined')
			} : null
		},

		isDemo: {
			get() {
				return this.demo
			},

			set(value) {
				this.$store.commit('ghost/setDemo', value)
			}
		}
	},

	methods: {
		print(){
			window.print()
		}
	}
}
</script>
