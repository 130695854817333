<template>
    <section v-if="isLoggedIn && isClient">
        <navigation />

        <div class="main-panel">
            <ghost-identity v-if="isGhost"/>
            <top-bar/>
            <datepicker v-show="$route.meta && $route.meta.datepicker" :topbarHeight="topbarHeight"/>
            <ajax-error-warning/>
            <div class="content content-mobile-nav">
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
  import Navigation from '@/app/layout/Navigation'
  import GhostIdentity from '@/app/shared/global/template/GhostIdentity'
  import TopBar from '@/app/shared/global/template/TopBar'
  import AjaxErrorWarning from '@/app/shared/global/warnings/AjaxErrorWarning'
  import Datepicker from '@/app/datepicker/components/Datepicker'
  import { mapGetters } from 'vuex'
  import bugsnagClient from '@/services/bugsnag/BugsnagService'
  import MixpanelService from '@/services/mixpanel/MixpanelService'

  export default {
    name: 'Frontend',
    data () {
      return {
        layout: false,
        errorThrottled: false,
        isMixpanelSetUp: false,
        isHotJarSetUp: false,
        topbarHeight: 0
      }
    },
    watch: {
      $route: 'trackPageView',

      customer: {
        handler: 'setup',
        immediate: true
      }
    },

    computed: {
      ...mapGetters('ghost', ['isGhost']),
      ...mapGetters('auth', ['isLoggedIn']),
      ...mapGetters('identity', {
        isClient: 'isClient',
        identity: 'getIdentity',
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      })
    },

    methods: {
      setup () {
        if (this.customer) {
          this.setUpMixpanel()
          this.commitUserToBugsnag()
        }
      },
      setUpMixpanel () {
        if (this.isMixpanelSetUp && this.isGhost) {
          return
        }

        this.isMixpanelSetUp = true

        if (!this.customer || !this.identity) {
          return
        }

        const organization = (this.organization && this.organization.slug) ? this.organization.slug : ''
        const source = this.customer.source
        const domain = this.customer.domain
        const email = this.identity.email
        const id = this.identity.id

        MixpanelService.setup(id, email, domain, organization, source)
        MixpanelService.track('App - Load')
      },

      trackPageView () {
        const route = this.$route

        if (!route || !route.meta || !route.meta.tracking) {
          return
        }

        const name = route.meta.tracking

        let properties = {}

        if (route.params) {
          properties = JSON.parse(JSON.stringify(route.params))
        }

        MixpanelService.track(name, properties)
      },

      commitUserToBugsnag () {
        if (!this.hasIdentity && this.isGhost) {
          return
        }

        const identity = this.identity

        bugsnagClient.user = {
          id: identity.id,
          type: identity.discr,
          email: identity.email
        }

      }
    },

    components: {
      GhostIdentity,
      TopBar,
      AjaxErrorWarning,
      Datepicker,
      Navigation,
    }
  }
</script>

<style scoped>

</style>
